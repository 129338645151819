@if (!powersLoading) {
  <div class="rules-tab-section h-full">
    <div class="rules-tab-content">
      <aside
        class="rules-left-side height-rules-left-side"
        [ngClass]="{ showRulesList: showRulesList }"
      >
        <div class="rules-left-side-menu border-b border-gray-200">
          <mat-list role="list" class="!p-0">
            <mat-list-item
              id="new-rule-menu"
              [disabled]="powerViewLoading"
              data-testid="new-rule-menu"
              role="listitem"
              [ngClass]="{
                'hover:!cursor-pointer hover:bg-gray-100': !powerViewLoading
              }"
              (click)="powerViewLoading ? null : addPowerToStation()"
            >
              <span class="flex items-center space-x-2"
                ><i class="fal fa-plus text-gray-400"></i>
                <span class="text-base">New Rule</span></span
              >
            </mat-list-item>
          </mat-list>
        </div>
        @if (!rulesLoading) {
          <section class="power-station-section">
            <p class="py-3 px-4 font-semibold text-sm text-secondary-300">
              {{ termsGeneric.Station.Single }} Rules
            </p>

            <div class="rules-left-side-added-rules scrollbar" cdkScrollable>
              <mat-list role="list" class="!p-0">
                @if (stationPowers.length) {
                  @for (power of stationPowers; track i; let i = $index) {
                    <mat-list-item
                      id="new-rule-menu"
                      data-testid="new-rule-menu"
                      role="listitem"
                      class="hover:!cursor-pointer hover:bg-gray-100"
                      (click)="rulesHandler(i)"
                    >
                      <span
                        class="text-base"
                        [ngClass]="{
                          'text-accent-500 font-semibold':
                            editedPower.rithmId === power.rithmId,
                          'option-disabled': powerViewLoading
                        }"
                        >{{
                          power.ruleName ||
                            'Default Rule: Flow to all ' +
                              (termsGeneric.Station.Plural | lowercase)
                        }}</span
                      >
                    </mat-list-item>
                  }
                } @else {
                  <mat-list-item role="listitem">
                    <span class="text-base text-gray-400"
                      >There are not rules created.</span
                    >
                  </mat-list-item>
                }
              </mat-list>
            </div>
          </section>
        } @else {
          <ng-container [ngTemplateOutlet]="rulesLoader" />
        }
      </aside>
      <div class="rules-right-side scrollbar" cdkScrollable>
        @if (!powerViewLoading) {
          <section class="flex flex-col h-full items-center">
            @if (stationPowers.length) {
              <ng-container [ngTemplateOutlet]="rulesTemplate" />
            } @else {
              <div
                class="flex flex-col h-full w-full items-center justify-center text-gray-400"
              >
                <p class="text-3xl">No rule has been selected.</p>
                <small>Please add a new rule or select any existing one.</small>
              </div>
            }
          </section>
        } @else {
          <ng-container [ngTemplateOutlet]="powersLoadingIndicator" />
        }
      </div>
    </div>
    <div class="rules-tab-footer">
      <button
        type="button"
        mat-stroked-button
        data-testid="button-power-cancel"
        id="button-power-cancel"
        (click)="cancelRulesChanges()"
        [disabled]="(!somethingHasChanged && !isValidTrigger) || powersLoading"
      >
        Cancel
      </button>
      <button
        type="button"
        mat-raised-button
        data-testid="button-power-save"
        id="button-power-save"
        color="accent"
        (click)="updateStationPowers()"
        [disabled]="(!somethingHasChanged && !isValidTrigger) || powersLoading"
      >
        Save
      </button>
    </div>
  </div>
} @else {
  <ng-container [ngTemplateOutlet]="powersLoadingIndicator" />
}

<!-- General loading indicator. -->
<ng-template #powersLoadingIndicator>
  <div class="flex h-full justify-center items-center">
    <app-loading-indicator id="component-power-loading" />
  </div>
</ng-template>

<!-- Load indicator in rules. -->
<ng-template #rulesLoader>
  <div class="flex w-full py-4 justify-center">
    <app-loading-indicator
      [inlineText]="'Loading Rules...'"
      id="loading-rules"
    />
  </div>
</ng-template>

<!-- Rules: Template to create or display rules.  -->
<ng-template #rulesTemplate>
  <section
    class="goback-arrow-section text-secondary-500 sm:hidden p-2 flex items-center"
  >
    <button type="button" mat-icon-button (click)="showRulesList = true">
      <i class="fa-solid fa-arrow-left fa-2x"></i>
    </button>
    <span class="mx-2">Rules</span>
  </section>
  <section class="rules-right-side-title">
    <div class="flex justify-start">
      @if (!editedRuleInfo) {
        <p class="flex items-center tracking-tight text-xl font-semibold h-10">
          {{
            editedPower.ruleName ||
              'Default Rule: Flow to all ' +
                (termsGeneric.Station.Plural | lowercase)
          }}
          <button
            type="button"
            data-testid="btn-edit-rule-name"
            id="btn-edit-rule-name"
            (click)="editedRuleInfo = true"
          >
            <i
              class="fa-solid fa-pencil text-xl ml-3 cursor-pointer inline-block"
            ></i>
          </button>
        </p>
      } @else {
        <div class="flex edit-rule-name w-full">
          <mat-form-field
            appearance="outline"
            class="w-full"
            subscriptSizing="dynamic"
          >
            <mat-label> Edit rule name</mat-label>
            <input
              matInput
              type="text"
              id="power-name-rule"
              data-testid="power-name-rule"
              autocomplete="off"
              [(ngModel)]="powerNameTemporal"
              (keyup)="
                powerNameTemporal.trim() &&
                  $event.keyCode === 13 &&
                  changePowerName()
              "
            />
            <button
              type="button"
              [disabled]="!powerNameTemporal.trim()"
              matSuffix
              id="save-power-name"
              data-testid="save-power-name"
              class="text-accent-300 mr-4"
              (click)="changePowerName()"
              [matTooltip]="'Click to set rule name'"
              [ngClass]="
                powerNameTemporal.trim()
                  ? 'cursor-pointer'
                  : 'cursor-not-allowed'
              "
            >
              <i class="fa-solid fa-xl fa-circle-check leading-5"></i>
            </button>
          </mat-form-field>
        </div>
      }
    </div>
    <button
      type="button"
      mat-icon-button
      data-testid="menu-power-station"
      id="menu-power-station"
      [matMenuTriggerFor]="menu"
    >
      <i
        [ngClass]="orderOfOperations ? 'fa-light' : 'fa-solid'"
        class="fa-ellipsis"
      ></i>
    </button>
    <mat-menu #menu="matMenu">
      <button
        type="button"
        data-testid="delete-power-station"
        id="delete-power-station"
        mat-menu-item
        (click)="deleteStationPowers(editedPower.rithmId)"
      >
        Delete Rule
      </button>
    </mat-menu>
  </section>

  <ng-template [ngTemplateOutlet]="triggersSection" />

  <ng-template [ngTemplateOutlet]="conditionsSection" />

  <section class="rules-right-side-actions w-full">
    <div
      class="title h-14 flex justify-between items-center bg-secondary-50 pl-8 pr-6"
    >
      <p class="tracking-tight text-sm">ACTIONS</p>
    </div>
    <div
      class="action-buttons-container flex-wrap items-center"
      [ngClass]="{
        'mt-5': !orderOfOperations,
        'overflow-x-auto': selectedActionTab !== '2'
      }"
    >
      <!-- Show actions when flag is true, and drag and drop.  -->
      @if (orderOfOperations) {
        <!-- component with logic to render actions and drag and drop.  -->
        <app-actions-list
          [stationRithmId]="rithmId"
          [actions]="editedPower.actions"
          [stations]="stations"
          [ruleRithmId]="editedPower.rithmId"
          [refreshIdContainer]="refreshIdContainer"
          [inputFrameWidgetItems]="inputFrameWidgetItems"
          [bucketQuestions]="bucketQuestions"
          [stationLibraryRelationships]="stationLibraryRelationships"
          [stationLibrariesLoading]="stationLibrariesLoading"
          [showOptionSqlIntegration]="showOptionSqlIntegration"
          [orderOfOperations]="orderOfOperations"
          [showRelationshipActions]="showRelationshipActions"
          [showAppendFieldActions]="showAppendFieldActions"
          [showActionMoveContainer]="showActionMoveContainer"
          [showAdvancedUpdateAction]="showAdvancedUpdateAction"
          [showParentChildRelationship]="showParentChildRelationship"
          [showNumberFieldRules]="showNumberFieldRules"
          [showFlowedBy]="showFlowedBy"
        />

        @if (!newAction) {
          <div class="pl-4 pb-4">
            <button
              mat-stroked-button
              type="button"
              data-testid="add-action"
              class="!bg-sky-500 !text-white !font-semibold"
              (click)="newAction = true"
            >
              <i class="fa-light fa-plus text-lg mr-2"></i>
              <span class="text-sm">ACTION</span>
            </button>
          </div>
        }
      }

      @if (!orderOfOperations || newAction) {
        <ng-template [ngTemplateOutlet]="actionsSection" />
      }
    </div>
  </section>
</ng-template>

<!-- Rules: Triggers in the rules.  -->
<ng-template #triggersSection>
  <section class="flex w-full">
    <app-triggers
      class="w-full"
      [stationButtons]="stationButtonsFlag$ | async"
      [ruleTriggers]="editedPower.triggers"
      [stationRithmId]="rithmId"
      [flowButtonName]="flowButtonName"
      [bucketQuestions]="bucketQuestions"
      [showUpdateFieldTrigger]="showUpdateFieldTrigger"
      (updatingTriggers)="hasBeenEdited()"
      (handleScheduleTrigger)="handleScheduleTrigger($event)"
      (standOutCustomId)="addCustomId()"
      (isValidTrigger)="isValidTrigger = $event"
    />
  </section>
</ng-template>

<!-- Rules: Conditions in the rules.  -->
<ng-template #conditionsSection>
  <section class="rules-right-side-conditions w-full">
    <div
      class="title h-14 flex justify-between items-center bg-secondary-50 pl-8 pr-6"
    >
      <p class="tracking-tight text-sm">CONDITIONS</p>
      <div class="select-condition-type">
        <mat-form-field
          [appearance]="orderOfOperations ? 'fill' : 'outline'"
          [formGroup]="conditionTypeForm"
          subscriptSizing="dynamic"
          class="bg-color-form-field"
        >
          @if (!orderOfOperations) {
            <mat-label class="text-base"> Condition type </mat-label>
          }

          <mat-select
            formControlName="conditionType"
            (selectionChange)="updateRuleType()"
          >
            <mat-option [value]="rulesType.And"> All must be true </mat-option>
            <mat-option [value]="rulesType.Or"> Any must be true </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="conditions-container">
      @if (!orderOfOperations) {
        <app-conditions
          #condition
          [stationQuestionPool]="bucketQuestions"
          [stationRithmId]="rithmId"
          [flagAttachmentsConditionsFilters]="flagAttachmentsConditionsFilters"
          [conditions]="editedPower.conditions"
          [refreshIdContainer]="refreshIdContainer"
          [stationLibraryRelationships]="stationLibraryRelationships"
          [stationLibrariesLoading]="stationLibrariesLoading"
          [assignedToConditions]="assignedToConditions"
          [showNumberFieldRules]="showNumberFieldRules"
          [attachmentFieldLogicFlag]="attachmentFieldLogicFlag"
          [orderOfOperations]="orderOfOperations"
          [ruleRithmId]="editedPower.rithmId"
          (somethingHasChanged)="hasBeenEdited()"
        />
      } @else {
        @for (condition of editedPower.conditions; track $index) {
          <app-conditions
            [stationQuestionPool]="bucketQuestions"
            [stationRithmId]="rithmId"
            [flagAttachmentsConditionsFilters]="
              flagAttachmentsConditionsFilters
            "
            [conditions]="[condition]"
            [refreshIdContainer]="refreshIdContainer"
            [stationLibraryRelationships]="stationLibraryRelationships"
            [stationLibrariesLoading]="stationLibrariesLoading"
            [assignedToConditions]="assignedToConditions"
            [showNumberFieldRules]="showNumberFieldRules"
            [attachmentFieldLogicFlag]="attachmentFieldLogicFlag"
            [orderOfOperations]="orderOfOperations"
            [ruleRithmId]="editedPower.rithmId"
            (deleteConditionEmit)="deleteConditionById($event)"
            (addCondition)="addCondition($event)"
          />
        }
        <div class="py-6">
          <app-conditions
            [stationQuestionPool]="bucketQuestions"
            [flagAttachmentsConditionsFilters]="
              flagAttachmentsConditionsFilters
            "
            [stationRithmId]="rithmId"
            [conditions]="[]"
            [refreshIdContainer]="refreshIdContainer"
            [stationLibraryRelationships]="stationLibraryRelationships"
            [stationLibrariesLoading]="stationLibrariesLoading"
            [assignedToConditions]="assignedToConditions"
            [showNumberFieldRules]="showNumberFieldRules"
            [attachmentFieldLogicFlag]="attachmentFieldLogicFlag"
            [orderOfOperations]="orderOfOperations"
            [showButtonNewCondition]="true"
            [conditionsCount]="editedPower.conditions.length"
            [ruleRithmId]="editedPower.rithmId"
            (addCondition)="addCondition($event)"
          />
        </div>
      }
    </div>
  </section>
</ng-template>

<!-- Rules: Actions in the rules.  -->
<ng-template #actionsSection>
  <mat-button-toggle-group
    name="actionButtons"
    aria-label="Action buttons"
    [(ngModel)]="selectedActionTab"
    [ngClass]="orderOfOperations ? 'button-group-sky' : 'button-group'"
    #groupTabsFlow="matButtonToggleGroup"
    [hideSingleSelectionIndicator]="true"
  >
    <mat-button-toggle
      value="0"
      class="toggle-conditions-tab"
      data-testid="toggle-button-flow"
      id="toggle-button-flow"
      >{{ termsGeneric.Flow.Single }}
    </mat-button-toggle>
    <mat-button-toggle
      value="1"
      class="toggle-conditions-tab"
      data-testid="toggle-button-container"
      id="toggle-button-container"
      >{{ termsGeneric.Container.Single }}
    </mat-button-toggle>
    <mat-button-toggle
      value="2"
      class="toggle-conditions-tab"
      data-testid="toggle-button-user"
      id="toggle-button-user"
      >User
    </mat-button-toggle>
    <mat-button-toggle
      value="3"
      class="toggle-conditions-tab"
      data-testid="toggle-button-notifications"
      id="toggle-button-notifications"
    >
      Notifications</mat-button-toggle
    >
    <mat-button-toggle
      value="4"
      class="toggle-conditions-tab"
      data-testid="toggle-button-integrations"
      id="toggle-button-integrations"
    >
      Integrations</mat-button-toggle
    >

    <mat-button-toggle
      value="5"
      class="toggle-conditions-tab"
      data-testid="toggle-button-script"
      id="toggle-button-script"
      >Script
    </mat-button-toggle>

    <mat-button-toggle
      value="6"
      class="toggle-conditions-tab"
      data-testid="toggle-button-advanced"
      id="toggle-button-advanced"
      >Advanced
    </mat-button-toggle>
  </mat-button-toggle-group>

  <div class="action-tabs-section w-full min-h-[500px] bg-white">
    <mat-tab-group
      class="action-tabs"
      [selectedIndex]="groupTabsFlow.value"
      animationDuration="0ms"
    >
      <!-- Tab Flow -->
      <mat-tab label="tab-flow" aria-label="tab-flow">
        @if (orderOfOperations) {
          <app-flow-container-form-action
            [ruleRithmId]="editedPower.rithmId"
            [nextStations]="stations"
            [totalActions]="editedPower.actions.length"
            (closeForm)="newAction = false"
          />
        } @else {
          <ng-container *ngTemplateOutlet="actionFlowContainer" />
        }
      </mat-tab>

      <!-- Tab Container -->
      <mat-tab label="tab-container" aria-label="tab-container">
        <section class="pt-6 pb-10">
          <app-container-actions
            #containerAction
            (actionsUpdEmitter)="addUpdateActionTypeContainer($event)"
            [stationRithmId]="rithmId"
            [actions]="!orderOfOperations ? editedPower.actions : []"
            [refreshIdContainer]="refreshIdContainer"
            [inputFrameWidgetItems]="inputFrameWidgetItems"
            [bucketQuestions]="bucketQuestions"
            [stationLibraryRelationships]="stationLibraryRelationships"
            [stationLibrariesLoading]="stationLibrariesLoading"
            [orderOfOperations]="orderOfOperations"
            [showRelationshipActions]="showRelationshipActions"
            [showAppendFieldActions]="showAppendFieldActions"
            [showActionMoveContainer]="showActionMoveContainer"
            [showAdvancedUpdateAction]="showAdvancedUpdateAction"
            [showParentChildRelationship]="showParentChildRelationship"
            [showFlowedBy]="showFlowedBy"
            [showNumberFieldRules]="showNumberFieldRules"
            [totalActions]="editedPower.actions.length"
            [newCreationAction]="true"
            [ruleRithmId]="editedPower.rithmId"
          />
        </section>
      </mat-tab>

      <!-- Tab User -->
      <mat-tab label="tab-user" aria-label="tab-user">
        <section class="pt-6 pb-10">
          <app-user-actions
            [orderOfOperations]="orderOfOperations"
            [stationRithmId]="rithmId"
            [actions]="editedPower.actions"
            [ruleRithmId]="editedPower.rithmId"
            [modeListOfActions]="true"
            [bucketQuestions]="bucketQuestions"
            [showManagementMembersForm]="showManagementMembersForm"
            [totalActions]="editedPower.actions.length"
            (actionsUpdEmitter)="hasBeenEdited()"
          />
        </section>
      </mat-tab>

      <!-- Tab Notification -->
      <mat-tab label="tab-notification" aria-label="tab-notification">
        <section
          class="tab-notification-content pt-6 pb-10"
          [ngClass]="
            orderOfOperations ? 'w-[calc(100%-15px)]' : 'overflow-x-hidden'
          "
        >
          <app-notifications-action
            #notificationAction
            [stationRithmId]="rithmId"
            [actions]="editedPower.actions"
            [refreshIdContainer]="refreshIdContainer"
            [orderOfOperations]="orderOfOperations"
            [newCreationAction]="true"
            [ruleRithmId]="editedPower.rithmId"
            (actionsUpdEmitter)="addUpdateActionTypeContainer($event)"
          />
        </section>
      </mat-tab>

      <!-- Tab Integrations -->
      <mat-tab label="tab-integrations" aria-label="tab-integrations">
        <section class="pt-6 pb-10">
          <app-integrations
            #IntegrationAction
            [actions]="editedPower.actions"
            [ruleRithmId]="editedPower.rithmId"
            [bucketQuestions]="bucketQuestions"
            [stationRithmId]="rithmId"
            [refreshIdContainer]="refreshIdContainer"
            [showList]="!orderOfOperations"
            [showOptionSqlIntegration]="showOptionSqlIntegration"
            [orderOfOperations]="orderOfOperations"
            (actionsUpdEmitter)="addUpdateActionTypeContainer($event)"
          />
        </section>
      </mat-tab>

      <!-- Tab Script -->
      <mat-tab label="tab-script" aria-label="tab-script">
        <section class="pt-6 pb-10 min-w-full max-w-[900px]">
          @if (selectedActionTab === '5' && isRuleSelectedSaved) {
            <app-scripts-actions
              [ruleRithmId]="editedPower.rithmId"
              [height]="heightScript"
              (getHeight)="getHeightScript($event)"
            />
          } @else {
            <ng-container [ngTemplateOutlet]="ruleSelectedNotSaved" />
          }
        </section>
      </mat-tab>

      <!-- Tab Advanced -->
      <mat-tab label="tab-advanced" aria-label="tab-advanced">
        <section class="pt-6 pb-10">
          <app-coming-soon-message />
        </section>
      </mat-tab>
    </mat-tab-group>
  </div>
</ng-template>

<!-- flow station template action. -->
<ng-template #actionFlowContainer let-showActionNewView="showActionNewView">
  <div
    class="action-tabs-section w-full bg-white"
    [ngClass]="{ 'min-h-[500px]': !orderOfOperations }"
  >
    <section
      class="tab-flow-content pt-6 pb-10"
      [ngClass]="{ 'px-8': !showActionNewView }"
    >
      <div class="flex items-center">
        @if (
          !showActionNewView &&
          (!orderOfOperations || (orderOfOperations && newAction))
        ) {
          <mat-form-field
            class="md:w-2/5 w-full"
            appearance="outline"
            [formGroup]="flowFieldForm"
            subscriptSizing="dynamic"
          >
            <mat-label class="text-base">
              Available {{ termsGeneric.Station.Plural }}
            </mat-label>
            <mat-select
              formControlName="stations"
              multiple
              (selectionChange)="flowStationSelect()"
            >
              @for (option of filteredStations$ | async; track $index) {
                <mat-option [value]="!orderOfOperations ? option.rithmId : ''">
                  {{ option.name }}
                </mat-option>
              }
            </mat-select>
          </mat-form-field>
        }
        @if (stationLoading) {
          <div class="flex items-center px-3">
            <app-loading-indicator
              [inlineText]="
                'Loading ' + termsGeneric.Station.Plural.toLowerCase() + '...'
              "
            />
          </div>
        }
      </div>
      <section class="station-chip-list">
        @if (!orderOfOperations) {
          <mat-chip-set
            #chipList
            [attr.aria-label]="termsGeneric.Station.Single + ' selection'"
          >
            @for (station of flowStations; track $index) {
              <mat-chip>
                {{ station.name }}
              </mat-chip>
            }
          </mat-chip-set>
        }
      </section>
      @if (orderOfOperations && newAction && !showActionNewView) {
        <div class="condition-control-buttons space-x-2 py-6">
          <button
            mat-button
            type="button"
            data-testid="condition-cancel"
            id="condition-cancel"
          >
            Cancel
          </button>
          <button type="button" class="!bg-sky-500 !text-white" mat-button>
            Save
          </button>
        </div>
      }
    </section>
  </div>
</ng-template>

<!-- Scripts: Rules selected not saved. -->
<ng-template #ruleSelectedNotSaved>
  <section id="ruleSelectedNotSaved" data-testid="ruleSelectedNotSaved">
    <div class="flex justify-center">
      <i class="fa-light fa-lock text-accent-500 fa-5x"></i>
    </div>
    <div class="flex justify-center">
      <div class="pt-4">
        <p class="text-center text-sm font-semibold pb-2">Script section</p>
        <p class="text-xs text-center text-primary-200">
          In order to be able to save and run the scripts, they must first be
          saved before.
        </p>
      </div>
    </div>
  </section>
</ng-template>
