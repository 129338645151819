import { CdkDragEnd, DragDropModule } from '@angular/cdk/drag-drop';
import { NestedTreeControl } from '@angular/cdk/tree';
import { CommonModule } from '@angular/common';
import {
  Component,
  effect,
  EventEmitter,
  input,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule, MatTreeNestedDataSource } from '@angular/material/tree';
import { LoadingIndicatorComponent } from 'src/app/shared/loading-indicator/loading-indicator.component';
import {
  CustomField,
  FiltersDataModel,
  GridsterItemWidget,
  Question,
  QuestionFieldIcon,
  QuestionFieldType,
  StationBucketQuestion,
  StationComponentListType,
  UpdateValueSingle,
  WidgetType,
  GenericWidget,
} from 'src/models';
import { StationService } from 'src/app/core/station.service';
import {
  ComponentHelper,
  InputFrame,
  JsonValidator,
  LibraryHelper,
  StationTerms,
  TermsGeneric,
} from 'src/helpers';
import { debounceTime, first, map, Subject, takeUntil, tap } from 'rxjs';
import { ContainerService } from 'src/app/core/container.service';
import _ from 'lodash';
import { BoardService } from 'src/app/board/board.service';

/** Identifies if the questions belong to bucket. */
interface BucketStationQuestion extends StationBucketQuestion {
  /** Identity whether questions is bucket. */
  isBucket?: boolean;
}

/** Identifies if the questions belong to bucket. */
interface BucketQuestion extends Question {
  /** Identity whether questions is bucket. */
  isBucket?: boolean;

  /** Identify whether questions is previous question. */
  isPreviousQuestion?: boolean;
}

/**
 * Component component tree.
 */
@Component({
  selector: 'app-component-tree[componentListType]',
  templateUrl: './component-tree.component.html',
  styleUrls: ['./component-tree.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatTreeModule,
    MatTooltipModule,
    DragDropModule,
    LoadingIndicatorComponent,
  ],
})
export class ComponentTreeComponent implements OnInit {
  /** Component Helper. */
  componentHelper = ComponentHelper;

  /** Will store all the questions stored within each frame. */
  private frameQuestions: Question[] = [];

  /** Readonly station fields. */
  private readonly readonlyStationFields = [
    QuestionFieldType.ContainerName,
    QuestionFieldType.StationId,
    QuestionFieldType.StationName,
    QuestionFieldType.ContainerId,
    QuestionFieldType.TimeInStation,
    QuestionFieldType.CreatedOn,
    QuestionFieldType.CreatedBy,
    QuestionFieldType.ParentStationRithmId,
    QuestionFieldType.ParentContainerRithmId,
    QuestionFieldType.ParentStationLink,
    QuestionFieldType.ParentContainerLink,
    QuestionFieldType.SelfAssign,
  ];

  /** Station Rithm id. */
  @Input() stationRithmId = '';

  /** List of all components on a station bucket. */
  stationBucketComponents = input<StationBucketQuestion[]>([]);

  /** List of container info components on a station bucket. */
  containerListChildren: CustomField[] = [];

  /** Contains the list of inputFrame created. */
  @Input() inputFrameList: string[] = [];

  /** Identify the list. */
  @Input() componentListType!: StationComponentListType;

  /** Whether the station is in edit mode or not. */
  @Input() editMode = false;

  /** Private expand tree. */
  _expandTreeInCustomId = false;

  /** Indicates whether the tree should be expanded.*/
  @Input() set expandTreeInCustomId(value: boolean) {
    this._expandTreeInCustomId = value;
    if (
      value &&
      this.componentListType === StationComponentListType.AvailableComponents
    ) {
      setTimeout(() => {
        const clonedTreeData = this.clonedTree();
        this.dataSource.data = clonedTreeData;
        this.treeControl.dataNodes = clonedTreeData;
        this.treeControl.dataNodes.map((rootNode) => {
          this.treeControl.expand(rootNode);
          rootNode.children?.map((childNode) => {
            if (childNode.enabled && childNode.prompt === 'Fields') {
              this.treeControl.expand(childNode);
            }
          });
        });
      }, 500);
    }
  }

  /** Feature flag for show parent station link in station bucket. */
  @Input({ required: true }) parentStationLinkFlag = false;

  /** Flag self_assign. */
  @Input({ required: true }) showSelfAssign = false;

  /** Feature flag for show parent container link in station bucket. */
  @Input({ required: true }) parentContainerLinkFlag = false;

  /**
   * Whether to expand the tree in the customId or not.
   * @returns A boolean.
   */
  get expandTreeInCustomId(): boolean {
    return this._expandTreeInCustomId;
  }

  /** Search text private. */
  private _searchText = '';

  /** The search text to filter the tree nodes. */
  @Input() set searchText(
    /** Input search text to filter. */
    value: string,
  ) {
    this._searchText = value.trim();
    this.filterChanged();
    this.checkContainerInfoQuestions();
  }

  /**
   * Get search text.
   * @returns A string.
   */
  get searchText(): string {
    return this._searchText;
  }

  /** Array with all the station frame input widgets. */
  _inputFrameWidgets: GridsterItemWidget[] = [];

  /** Input frame widget.*/
  @Input() set inputFrameWidgets(value: GridsterItemWidget[]) {
    this._inputFrameWidgets = value;
    this.checkContainerInfoQuestions();
  }

  /**
   * Get input frame.
   * @returns Array questions frame.
   */
  get inputFrameWidgets(): GridsterItemWidget[] {
    return this._inputFrameWidgets;
  }

  /** Output value when component tree data received. */
  @Output() componentTreeDataReceived = new EventEmitter<boolean>();

  /** Enable dragged mode when drag a item. */
  @Output() dragMode = new EventEmitter<boolean>();

  /** Enable drop zone in local component when node station component is expanded. */
  @Output() enableDropZone = new EventEmitter<boolean>();

  /** Open setting tab when clicked in bucket. */
  @Output() openSettingTab = new EventEmitter<
    Question | StationBucketQuestion
  >();

  /** Observable for when the component is destroyed. */
  private destroyed$ = new Subject<void>();

  /** Question icons. */
  questionFieldIcon = QuestionFieldIcon;

  /** Question field type. */
  questionFieldType = QuestionFieldType;

  /** Station component list type. */
  stationComponentListType = StationComponentListType;

  /** Widget types list.  */
  widgetType = WidgetType;

  /** Build the tree with of children of the items.*/
  treeControl = new NestedTreeControl<
    CustomField | (BucketQuestion | BucketStationQuestion)
  >((node) => node.children);

  /** It will contain the data for the tree. */
  dataSource = new MatTreeNestedDataSource<CustomField>();

  /** Station terms question type name. */
  questionTypeName = StationTerms.questionTypeName;

  /** Input tree data. */
  inputTreeData = StationTerms.ContainerTreeElements.filter(
    (element) => element.questionType !== this.questionFieldType.RadioList,
  );

  /** Encapsule station component for validate node in template. */
  stationComponentName = `${TermsGeneric.Station.Single} Components`;

  /** Empty element for customField. */
  emptyCustomField: Question = {
    prompt: 'empty template',
    rithmId: '',
    enabled: false,
    questionType: QuestionFieldType.Button,
    isReadOnly: false,
    isRequired: false,
    isPrivate: false,
    children: [],
  };

  /** Station bucket data structure. */
  stationBucketData: CustomField[] = [
    {
      prompt: `${TermsGeneric.Station.Single} Bucket`,
      enabled: true,
      icon: 'fa-light fa-bucket',
      children: [
        {
          prompt: `${TermsGeneric.Container.Single} Info`,
          enabled: true,
          children: this.containerListChildren,
        },
        {
          prompt: this.stationComponentName,
          enabled: true,
          children: [this.emptyCustomField],
        },
      ],
    },
  ];

  /** Station bucket data structure. */
  availableComponents: CustomField[] = [
    {
      prompt: 'New Components',
      enabled: true,
      icon: 'fa-light fa-square-dashed-circle-plus',
      children: this.inputTreeData.concat({
        prompt: 'Button',
        rithmId: '',
        enabled: true,
        questionType: QuestionFieldType.Button,
        isReadOnly: false,
        isRequired: false,
        isPrivate: false,
        children: [],
        triggerId: '',
      }),
    },
  ];

  /** Formula Components. */
  formulaComponents: CustomField[] = [
    {
      prompt: 'Formula',
      enabled: true,
      icon: 'fa-light fa-calculator-simple',
      children: [
        {
          prompt: 'Function',
          rithmId: '',
          enabled: true,
          questionType: QuestionFieldType.Function,
          isReadOnly: false,
          isRequired: false,
          isPrivate: false,
          children: [],
          settings: '',
        },
      ],
    },
  ];

  /** Current question bucket data. */
  currentBucketData: (Question | StationBucketQuestion)[] = [];

  /** Current question bucket data copy. */
  copyCurrentBucketData: (Question | StationBucketQuestion)[] = [];

  /** Possible new bucket question. */
  possibleBucketQuestions: Question[] = [];

  /** Loading while get data for station components. */
  loadingStationComponent = false;

  /** Whether has been ocurred a error while get data for station components. */
  isErrorLoadingStationComponent = false;

  /** Disable question. */
  disableQuestion = '';

  /** Temporary field name from setting tab. */
  temporaryFieldName: UpdateValueSingle[] = [];

  constructor(
    private stationService: StationService,
    private containerService: ContainerService,
    private boardService: BoardService,
  ) {
    effect(() => {
      this.containerListChildren = StationTerms.ContainerInfoItems.map(
        (field) => {
          const rithmId =
            this.stationBucketComponents()?.find(
              ({ questionType }) => questionType === field.questionType,
            )?.rithmId ?? '';

          return {
            ...field,
            rithmId,
          };
        },
      );

      if (this.stationBucketData[0]?.children) {
        this.stationBucketData[0].children[0].children =
          this.containerListChildren;
      }
    });
  }

  /**
   * Listen changes in relationship prompt.
   */
  private updateRelationshipPrompt$(): void {
    this.boardService.handleRelationshipPrompt$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((relWidget) => {
        if (!this.stationBucketData[0].children?.length) return;
        this.stationBucketData[0]?.children[1]?.children?.map((comp) => {
          const component = comp as StationBucketQuestion;
          if (
            component.rithmId === relWidget.rithmId &&
            component.questionType === WidgetType.RelationshipWidget
          ) {
            const relationshipData =
              JsonValidator.getObjectFromString<FiltersDataModel>(
                relWidget.data,
              );
            if (component.prompt !== relationshipData.prompt) {
              component.prompt = relationshipData.prompt || '';
              component.data = JSON.stringify(relationshipData);
            }
          }
        });
      });
  }

  /**
   * Init Component.
   *
   */
  ngOnInit(): void {
    this.enableQuestions();
    this.updateRelationshipPrompt$();
    this.subscribeContainerLabelText$();

    if (this.componentListType === StationComponentListType.LocalComponent) {
      this.bucketQuestionUpdate$();
      this.duplicateBucketQuestion$();
      this.getStationComponents();
      this.subscribeUpdateQuestionFieldValueById$();
    }
  }

  /**
   * Assign data selected following component type.
   * @returns Data selected.
   */
  assignDataSelected(): CustomField[] {
    return this.componentListType === StationComponentListType.LocalComponent
      ? this.stationBucketData
      : this.componentListType === StationComponentListType.FormulaComponents
        ? this.formulaComponents
        : this.availableComponents;
  }

  /**
   * Clone current tree.
   * @returns An array.
   */
  clonedTree(): CustomField[] {
    return this.stationService.componentTreeNodesCopy(
      this.assignDataSelected(),
    );
  }

  /**
   * Check whether field is into frame.
   * @param node Field To check.
   * @returns If the field is into frame.
   */
  isFieldIntoFrame(node: StationBucketQuestion): boolean {
    return this.frameQuestions.some((e) => e.rithmId === node.rithmId);
  }

  /** Subscribe to containerLabelText$. */
  private subscribeContainerLabelText$(): void {
    this.containerService.containerLabelText$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((containerLabel) => {
        const field = this.containerListChildren.find(
          ({ questionType }) =>
            questionType === QuestionFieldType.ContainerName,
        );

        if (field) {
          field.prompt = containerLabel.value || TermsGeneric.Container.Single;
        }
      });
  }

  /** Subscribe to updateQuestionFieldValueById$. */
  private subscribeUpdateQuestionFieldValueById$(): void {
    this.containerService.updateQuestionFieldValueById$
      .pipe(debounceTime(500), takeUntil(this.destroyed$))
      .subscribe((value) => {
        const indexBucket = this.currentBucketData.findIndex(
          ({ rithmId }) => rithmId === value.rithmId,
        );

        let indexPossible = -1;
        indexBucket === -1 &&
          (indexPossible = this.possibleBucketQuestions.findIndex(
            ({ rithmId }) => rithmId === value.rithmId,
          ));

        if (indexBucket > -1 || indexPossible > -1) {
          this.temporaryFieldName.push(value);
          const bucketData =
            indexBucket > -1
              ? this.currentBucketData[indexBucket]
              : this.possibleBucketQuestions[indexPossible];

          bucketData.prompt = value.value;
          this.updateBucketData();
        }
      });
  }

  /** Update the bucket question list. */
  private bucketQuestionUpdate$(): void {
    this.stationService.bucketQuestionUpdate$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((question) => {
        if (question.questionType === QuestionFieldType.AddressLine)
          question.children = [];
        if (
          !this.containerListChildren.some(
            (containerFieldInfo) =>
              containerFieldInfo.questionType === question.questionType,
          )
        ) {
          // Find the index of the question in the possibleBucketQuestions array.
          const indexPossible = this.possibleBucketQuestions.findIndex(
            ({ rithmId }) => rithmId === question.rithmId,
          );
          // Initialize the bucket index to -1.
          let indexBucket = -1;
          // If the question is not found in possibleBucketQuestions.
          // Find it in currentBucketData.
          if (indexPossible === -1) {
            indexBucket = this.currentBucketData.findIndex(
              ({ rithmId }) => rithmId === question.rithmId,
            );
          }
          // If the question is not found in currentBucketData
          // Add or remove it from possibleBucketQuestions
          if (indexBucket === -1) {
            indexPossible > -1
              ? this.possibleBucketQuestions.splice(indexPossible, 1)
              : this.possibleBucketQuestions.push(question);
          }
        }
        this.updateBucketData();
      });
  }

  /** Replace the duplicate question with the one in the current bucket. */
  private duplicateBucketQuestion$(): void {
    this.stationService.duplicateBucketQuestion$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((question) => {
        // We search the index through the original question from which it was copied.
        const indexBucketQ = this.possibleBucketQuestions.findIndex(
          ({ rithmId }) => rithmId === question.originalQuestionRithmId,
        );
        if (indexBucketQ > -1) {
          // We replace by the grid field.
          this.possibleBucketQuestions[indexBucketQ] = question;
          this.updateBucketData();
        }
      });
  }

  /**
   * Get Previous Questions.
   *
   */
  getStationComponents(): void {
    this.loadingStationComponent = true;
    this.isErrorLoadingStationComponent = false;
    this.stationService
      .getStationBucketQuestions(this.stationRithmId)
      .pipe(
        tap(() => {
          this.loadingStationComponent = false;
        }),
        map((bucketData) => {
          return this.removeDuplicateQuestions(bucketData).filter(
            ({ prompt, questionType }) =>
              prompt !== 'Address Line 1' &&
              prompt !== 'Address Line 2' &&
              questionType !== QuestionFieldType.City &&
              questionType !== QuestionFieldType.State &&
              questionType !== QuestionFieldType.Zip &&
              !this.containerListChildren.some(
                (containerFieldInfo) =>
                  containerFieldInfo.questionType === questionType,
              ),
          );
        }),
        map((bucketData) => {
          return bucketData.map((bucket) => ({
            ...bucket,
            isBucket: true,
          }));
        }),
        map((bucketData) => {
          return bucketData.map((component) => {
            if (component.questionType === QuestionFieldType.AddressLine)
              component.children = [];
            return component;
          });
        }),
        map((bucketData) => {
          return bucketData.map((component) => {
            if (component.questionType === WidgetType.RelationshipWidget) {
              component.widget = _.cloneDeep(
                this.inputFrameWidgets.find(
                  (widget) => widget.rithmId === component.rithmId,
                ),
              );
            }
            return component;
          });
        }),

        first(),
      )
      .subscribe({
        next: (bucketQuestions) => {
          this.currentBucketData = bucketQuestions;
          this.copyCurrentBucketData = _.cloneDeep(this.currentBucketData);
          this.updateBucketData();
          this.loadingStationComponent = false;
        },
        error: () => {
          this.loadingStationComponent = false;
          this.isErrorLoadingStationComponent = true;
        },
      });
  }

  /**
   * Update tree for node station component bucket.
   */
  updateBucketData(): void {
    // If there is a question in the frame and it is not saved, the question must also be displayed in the list
    const buckets = _.cloneDeep(
      this.possibleBucketQuestions.length && this.editMode
        ? this.currentBucketData.concat(this.possibleBucketQuestions)
        : this.currentBucketData,
    );
    const isEmpty = buckets.length === 0;

    if (this.stationBucketData[0].children) {
      if (isEmpty) {
        buckets.push(this.emptyCustomField);
        this.treeControl.collapse(this.stationBucketData[0].children[1]);
      }

      if (this.temporaryFieldName.length) {
        this.temporaryFieldName.forEach((temporary) => {
          const index = buckets.findIndex(
            ({ rithmId }) => rithmId === temporary.rithmId,
          );
          index > -1 && (buckets[index].prompt = temporary.value);
        });
      }

      this.stationBucketData[0].children[1].children = buckets;
      this.stationBucketData[0].children[1].enabled = !isEmpty;
      if (this.searchText) {
        this.filterChanged();
      } else {
        this.dataSource.data = [];
        this.dataSource.data = this.stationBucketData;
      }

      this.checkBucketQuestions();
    }
  }

  /**
   * Remove questions duplicates.
   * @param questions Array questions.
   * @returns Array without elements duplicates.
   */
  private removeDuplicateQuestions(
    questions: (BucketQuestion | BucketStationQuestion)[],
  ): (BucketQuestion | BucketStationQuestion)[] {
    const keys: { [keys: string]: boolean } = {};
    return questions.filter((Q) => {
      return keys[Q.rithmId] ? false : (keys[Q.rithmId] = true);
    });
  }

  /**
   * Enable questions.
   */
  private enableQuestions(): void {
    this.dataSource.data = this.assignDataSelected();
    this.componentTreeDataReceived.emit(true);
  }

  /**
   * Check whether the library contains children or not.
   * @param index Index.
   * @param node Node of tree.
   * @returns A boolean.
   */
  hasChild = (index: number, node: CustomField | Question): boolean => {
    return (
      // If it has children and is different than AddressLine to display it as a listing.
      !!node.children &&
      node.children.length > 0 &&
      node.questionType !== QuestionFieldType.AddressLine
    );
  };

  /**
   * Filters the tree data to match search text.
   *
   */
  private filterChanged(): void {
    const clonedTreeData = this.clonedTree();
    this.removeUnMatchingNodes(clonedTreeData, this.searchText);
    this.dataSource.data = clonedTreeData;
    this.treeControl.dataNodes = clonedTreeData;
    this.searchText
      ? this.treeControl.dataNodes.map((rootNode, index) => {
          this.trackBy(index, rootNode as CustomField);
          rootNode.children?.forEach((childNode, i) => {
            this.trackBy(i, childNode as CustomField);
            if (childNode.enabled) {
              this.treeControl.expand(childNode);
            }
            this.treeControl.expand(rootNode);
          });
        })
      : this.treeControl.collapseAll();
  }

  /**
   * Filters the tree data to match search text.
   * @param tree Text needs to be searched in tree.
   * @param searchString Text needs to be searched in tree.
   * @returns If any node has to be displayed or not.
   */
  private removeUnMatchingNodes(
    tree: CustomField[] | (Question | StationBucketQuestion)[],
    searchString: string,
  ): boolean {
    for (let index = tree.length - 1; index >= 0; index--) {
      const node = tree[index];
      if (
        node.children &&
        'prompt' in node &&
        node.prompt
          .toLocaleLowerCase()
          .indexOf(searchString.toLocaleLowerCase()) === -1
      ) {
        const canRemoveParent = this.removeUnMatchingNodes(
          node.children,
          searchString,
        );
        if (canRemoveParent) {
          if (
            node.prompt
              .toLocaleLowerCase()
              .indexOf(searchString.toLocaleLowerCase()) === -1
          ) {
            tree.splice(index, 1);
          }
        }
      } else {
        // Its a leaf node. No more branches.
        if (
          'prompt' in node &&
          node.prompt
            .toLocaleLowerCase()
            .indexOf(searchString.toLocaleLowerCase()) === -1
        ) {
          tree.splice(index, 1);
        }
      }
    }
    return tree.length === 0;
  }

  /**
   * Return if a container-info question should be enabled or disabled.
   */
  async checkContainerInfoQuestions(): Promise<void> {
    this.frameQuestions = this.inputFrameWidgets.length
      ? await InputFrame.extractFrameQuestions(this.inputFrameWidgets)
      : [];
    this.dataSource.data.forEach((mainBranch) => {
      if (mainBranch && mainBranch.children) {
        mainBranch.children[0].children?.forEach((libQuestion) => {
          if (
            this.readonlyStationFields.includes(
              libQuestion.questionType as QuestionFieldType,
            )
          ) {
            if ('enabled' in libQuestion)
              if (this.frameQuestions.length) {
                libQuestion.enabled = !this.frameQuestions.find(
                  (q) =>
                    q.questionType ===
                    (libQuestion.questionType as QuestionFieldType),
                );
              } else {
                libQuestion.enabled = true;
              }
          }
        });
      }
    });
    this.checkBucketQuestions();
  }

  /**
   * Check bucket station questions.
   */
  async checkBucketQuestions(): Promise<void> {
    const containerQuestionType: QuestionFieldType[] =
      this.containerListChildren.map(
        ({ questionType }) => questionType as QuestionFieldType,
      );
    this.dataSource.data.forEach(({ children }) => {
      if (children && children.length > 0) {
        const node = children.find(
          ({ prompt }) => prompt === this.stationComponentName,
        );
        node?.children?.forEach((libQuestion) => {
          if (libQuestion.questionType !== WidgetType.RelationshipWidget) {
            libQuestion.enabled = !this.frameQuestions.some(
              ({ rithmId, questionType }) =>
                rithmId ===
                  (libQuestion as Question | StationBucketQuestion).rithmId ||
                (questionType === libQuestion.questionType &&
                  containerQuestionType.includes(libQuestion.questionType)),
            );
          } else {
            libQuestion.enabled = !this.inputFrameWidgets.find(
              ({ rithmId }) =>
                rithmId ===
                (libQuestion as Question | StationBucketQuestion).rithmId,
            );
          }
        });
      }
    });
  }

  /**
   * Check widgets in the grid.
   * @param widget Widget selected.
   */
  checkWidgetOnGrid(widget: StationBucketQuestion): void {
    this.dataSource.data.forEach(({ children }) => {
      children?.forEach((child) => {
        child.children?.map((childWidget) => {
          const widgetInProgress = childWidget as StationBucketQuestion;
          if (
            widgetInProgress.rithmId === widget.rithmId &&
            LibraryHelper.genericWidgetTypes.includes(
              widgetInProgress.questionType as GenericWidget,
            )
          ) {
            widgetInProgress.enabled = false;
          }
        });
      });
    });
  }

  /**
   * Enable/Disable drag mode.
   * @param status Whether drag mode is enable/disable.
   */
  dragModeEvent(status: boolean): void {
    if (status && document.body.style.cursor !== 'grabbing') {
      document.body.style.cursor = 'grabbing';
    }
    this.dragMode.emit(status);
  }

  /**
   * Detect whether node clicked is 'station components'.
   * @param node Node Selected.
   */
  treeNodeToggle(node: CustomField): void {
    if (node.prompt === this.stationComponentName) {
      const isExpanded = this.treeControl.isExpanded(node);
      this.enableDropZone.emit(isExpanded);
    }
  }

  /** Restore bucket data when cancel changes on grid. */
  restoreBucketData(): void {
    this.temporaryFieldName = [];
    this.currentBucketData = _.cloneDeep(this.copyCurrentBucketData);
    this.possibleBucketQuestions = [];
    this.updateBucketData();
  }

  /**
   * Open setting tab when clicked in bucket.
   * @param node Question to open.
   */
  openSettingTabEvent(node: Question | StationBucketQuestion): void {
    if ((this.editMode, node.enabled)) {
      this.openSettingTab.emit(node);
    }
  }

  /**
   * Feature flag for show parent station/container link.
   * @param node Question type To check.
   * @returns A boolean.
   */
  filterLeafNode(node: Question | StationBucketQuestion): boolean {
    return (
      (this.questionFieldType.ParentStationLink === node.questionType &&
        this.parentStationLinkFlag) ||
      (this.questionFieldType.ParentContainerLink === node.questionType &&
        this.parentContainerLinkFlag)
    );
  }

  /**
   * TrackBy in *ngFor, to the nodes of the tree.
   * @param index Number of index *ngFor.
   * @param item Number of index *ngFor.
   * @returns An item.
   */
  trackBy(
    index: number,
    item: CustomField | BucketQuestion | BucketStationQuestion,
  ): CustomField | BucketQuestion | BucketStationQuestion {
    return item;
  }

  /**
   * Drag finished.
   * @param event Contains the event and element that ended the drag.
   */
  dragFinished(event: CdkDragEnd<StationBucketQuestion>): void {
    this.checkWidgetOnGrid(event.source.dropContainer.data[0]);
    if (document.body.style.cursor !== 'auto') {
      document.body.style.cursor = 'auto';
    }
  }
}
