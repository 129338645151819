import { CommonModule } from '@angular/common';
import {
  Component,
  effect,
  EventEmitter,
  inject,
  Input,
  input,
  model,
  OnDestroy,
  OnInit,
  Output,
  output,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { OrganizationService } from 'src/app/core/organization.service';
import { UserAvatarComponent } from 'src/app/shared/user-avatar/user-avatar.component';
import { v4 as uuidv4 } from 'uuid';
import {
  ActionType,
  EntityType,
  ManagementMembersActionData,
  ManagementMembersActionDataItem,
  ManagementMembersData,
  PowerAction,
  PowerEventType,
  Question,
  QuestionFieldIcon,
  QuestionFieldType,
  StaticMemberQuestions,
  TypeMember,
} from 'src/models';
import { debounceTime, first, skip, Subject, takeUntil } from 'rxjs';
import { StationOrGroup } from 'src/models/station-or-group';
import { TermsGeneric } from 'src/helpers';
import { PopupService } from 'src/app/core/popup.service';
import { LoadingIndicatorComponent } from 'src/app/shared/loading-indicator/loading-indicator.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { InfinityScrollDirective } from 'src/helpers/directives/infinity-scroll-directive/infinity-scroll.directive';
import { PowerService } from 'src/app/core/power.service';
import { MatSelectInfinityScrollDirective } from 'src/helpers/directives/mat-select-infinite-scroll/mat-select-infinite-scroll.directive';
import { UserService } from 'src/app/core/user.service';
import { StationGroupService } from 'src/app/core/station-group.service';
import _ from 'lodash';
import { StationService } from 'src/app/core/station.service';

/**
 * Component to show logic of action management members action.
 */
@Component({
  selector: 'app-management-members-action-form',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatChipsModule,
    MatTableModule,
    MatInputModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatButtonModule,
    MatAutocompleteModule,
    NgxMatSelectSearchModule,
    UserAvatarComponent,
    InfinityScrollDirective,
    LoadingIndicatorComponent,
    MatSelectInfinityScrollDirective,
  ],
  templateUrl: './management-members-action-form.component.html',
  styleUrl: './management-members-action-form.component.scss',
})
export class ManagementMembersActionFormComponent implements OnInit, OnDestroy {
  /** Observable for when the component is destroyed. */
  private destroyed$ = new Subject<void>();

  /** Feature flag order of operations. */
  @Input({ required: true }) orderOfOperations = false;

  /** Action management members. */
  action = model.required<PowerAction | null>();

  /** Order for new action. */
  orderAction = input<number>(0);

  /** Bucket questions. */
  bucketQuestions = input.required<Question[]>();

  /** The station id used to get shared values. */
  stationRithmId = input.required<string>();

  /** Current rithmId of the rule. */
  ruleRithmId = input.required<string>();

  /** Object action. */
  actionManagementMember = output<PowerAction>();

  /** Close event. */
  @Output() closeEvent = new EventEmitter<void>();

  /** Emits when an action has been updated. */
  @Output() hasBeenUpdated = new EventEmitter<void>();

  /** Columns statics to show on table. */
  displayedColumns = ['check', 'member', 'worker'];

  /* System-wide generic terms. */
  termsGeneric = TermsGeneric;

  /** Data of action management members. */
  dataAction!: ManagementMembersActionData;

  /** Actions type. */
  actionsType = ActionType;

  /** Members for station,group or organization. */
  members: ManagementMembersData[] = [];

  /** Data source. */
  dataSourceTable: MatTableDataSource<
    ManagementMembersData | StaticMemberQuestions
  > = new MatTableDataSource();

  /** Data of groups and stations of org. */
  listStationsAndGroups: StationOrGroup[] = [];

  /** Current station option to show in station and group select list. */
  currentStationOption!: StationOrGroup;

  /** Element selected from table. */
  selectionsTable: {
    /** Key member. */
    [key: string]: {
      /** Member id. */
      id: string;

      /** If the member is worker. */
      isWorker: boolean;

      /** If the member is selected. */
      isMember: boolean;

      /** First name. */
      firstName: string;

      /** Lats name. */
      lastName?: string;

      /** Profile image rithmId. */
      profileImageRithmId?: string;

      /** Member type selected. */
      type:
        | TypeMember
        | QuestionFieldType.AssignedUser
        | QuestionFieldType.UserSelect
        | QuestionFieldType.CreatedBy;
    };
  } = {};

  /** Station and groups indexes. */
  stationsAndGroups: {
    [key: string]: {
      /** RithmId. */
      rithmId: string;
      /** Name element. */
      name: string;
      /** Description. */
      description: string;
      /** Type element. */
      type: 'station' | 'group';
    };
  } = {};

  /** Action to edit. */
  actionToEdit!: ManagementMembersActionData;

  /** Page num get members. */
  pageNumMembers = 1;

  /** Elements by request. */
  pageSize = 30;

  /** Search members. */
  searchMembers = '';

  /** If all members has been selected. */
  allMemberSelected = false;

  /** Loading update action. */
  loadingUpdatingAction = false;

  /** Is members loading. */
  isMembersLoading = false;

  /** Is loading to get container by scroll. */
  isLoadingScroll = false;

  /** Whether or not you can load more pages for the table. */
  canLoadPages = false;

  /** Deleting action indicator. */
  deletingAction = false;

  /** Object ManagementMembersData or StaticMemberQuestions type. */
  questionsElementStatics: (ManagementMembersData | StaticMemberQuestions)[] = [
    {
      rithmId: 'info:assignedTo',
      firstName: 'Assigned to',
      memberType: QuestionFieldType.AssignedUser,
      icon: QuestionFieldIcon[QuestionFieldType.AssignedUser],
    },
    {
      rithmId: `info:${QuestionFieldType.CreatedBy}`,
      firstName: 'Created By',
      memberType: QuestionFieldType.CreatedBy,
      icon: QuestionFieldIcon[QuestionFieldType.CreatedBy],
    },
  ];

  /** Page number to get stations and groups. */
  pageNumStationAndGroup = 1;

  /** Maximum number of stations and groups to be shown in the list. */
  pageSizeStationAndGroup = 30;

  /** Search station and group value. */
  searchStationAndGroup = '';

  /** Whether or not you can load more stations and groups for the list. */
  canLoadMoreStationsAndGroups = false;

  /** Whether or not the request to get the stations and groups is loading or not. */
  isLoadingStationsAndGroups = false;

  /** Whether or not there has been an error in the request to obtain stations and groups. */
  isErrorGetStationsAndGroups = false;

  /** Subject to update search.*/
  private searchUpdated$: Subject<string> = new Subject<string>();

  /** Service of organization service. */
  private organizationService: OrganizationService =
    inject(OrganizationService);

  /** Service of popup's. */
  private popupService: PopupService = inject(PopupService);

  /** Service of PowerService. */
  private powerService: PowerService = inject(PowerService);

  /** Service of user service. */
  private userService: UserService = inject(UserService);

  /** Service of station group service. */
  private stationGroupService: StationGroupService =
    inject(StationGroupService);

  /** Service of the station. */
  private stationService: StationService = inject(StationService);

  /** Initial backup of rithmId's of stations and groups. */
  private stationAndGroupsBackupIds: string[] = [];

  /** Form group for the use of the 'station or group' element. */
  memberActionForm: FormGroup = new FormGroup({
    elementMemberAction: new FormControl('', [Validators.required]),
    elementSelectControl: new FormControl([], [Validators.required]),
    elementSearchControl: new FormControl('', {
      nonNullable: true,
    }),
  });

  constructor() {
    this.searchUpdated$
      .pipe(debounceTime(750), takeUntil(this.destroyed$))
      .subscribe((value) => {
        this.searchMembers = value;
        this.getSearchResult();
      });

    effect(() => {
      if (this.action()) {
        this.actionToEdit = JSON.parse(
          this.action()?.data || '',
        ) as ManagementMembersActionData;
        this.getStationsAndGroups(
          true,
          this.actionToEdit.target.stations.length +
            this.actionToEdit.target.groups.length,
        );
        this.editAction();
      }
    });
  }

  /**
   * Listen to the changes coming from the station component.
   */
  private subscribeCurrentStationOption$(): void {
    this.stationService.currentStationOption$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((currentStation) => {
        this.currentStationOption = currentStation;
      });
  }

  /** Method to init life cycle. */
  ngOnInit(): void {
    //Variable to get data information to action.
    if (this.action()?.data) {
      this.dataAction = JSON.parse(this.action()?.data || '');
    }
    this.bucketQuestions()
      .filter((x) => x.questionType === QuestionFieldType.UserSelect)
      .forEach((question) => {
        this.questionsElementStatics.push({
          rithmId: question.rithmId,
          firstName: question.prompt || 'User Select',
          memberType: QuestionFieldType.UserSelect,
          icon: 'fak fa-user-select',
        });
      });

    this.dataSourceTable.data = this.questionsElementStatics;
    this.getMembersAllOrg();
    this.subscribeCurrentStationOption$();
    this.getStationsAndGroups();
    this.ngxSearch$();
    this.reloadMembersByStationOrGroup$();
    this.elementMemberActionForm$();
  }

  /** Subscribe to teload members by a specific station or stationGroup. */
  private reloadMembersByStationOrGroup$(): void {
    this.stationGroupService.reloadMembersGroupStation$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((currentRithmId) => {
        // If the id is the same as the table id we do a reload.
        if (this.stationRithmId() === currentRithmId) {
          this.getMembersAllOrg(false, true);
        }
      });
  }

  /**
   * Subscribe to elementSearchControl changes.
   */
  ngxSearch$(): void {
    this.memberActionForm.controls.elementSearchControl.valueChanges
      .pipe(debounceTime(750), takeUntil(this.destroyed$))
      .subscribe((search) => {
        this.pageNumStationAndGroup = 1;
        this.searchStationAndGroup = search;
        this.getStationsAndGroups();
      });
  }

  /**
   * Subscribe to elementMemberAction changes.
   */
  elementMemberActionForm$(): void {
    // I used skip to avoid the call when setting the initial values in the forms in the case of editing.
    this.memberActionForm.controls.elementMemberAction.valueChanges
      .pipe(skip(this.action() ? 1 : 0), takeUntil(this.destroyed$))
      .subscribe(() => {
        if (Object.keys(this.selectionsTable).length)
          this.popupService.notify(
            'Please, if you wish, select the members of the action, corresponding to the new type of action.',
          );
        this.selectionsTable = {};
        this.allMemberSelected = false;
      });
  }

  /**
   * If stations and group has been loaded.
   * @returns If is loaded.
   */
  isLoadedStationAndGroup(): boolean {
    return Object.keys(this.stationsAndGroups).length > 0;
  }

  /**
   * Get list of stations and groups of organization.
   * @param isGetSpecificData If the request if for get specific stations and group.
   * @param pageSize Size to get data.
   */
  getStationsAndGroups(isGetSpecificData = false, pageSize = 30): void {
    this.isLoadingStationsAndGroups = true;
    this.isErrorGetStationsAndGroups = false;
    isGetSpecificData && this.disableControlForm('elementSelectControl');
    this.organizationService
      .getStationsAndGroups(
        this.searchStationAndGroup,
        isGetSpecificData ? pageSize : this.pageSizeStationAndGroup,
        this.pageNumStationAndGroup,
        isGetSpecificData ? this.actionToEdit?.target.stations : [],
        isGetSpecificData ? this.actionToEdit?.target.groups : [],
      )
      .pipe(first())
      .subscribe({
        next: (elements) => {
          if (!isGetSpecificData) {
            this.listStationsAndGroups =
              this.pageNumStationAndGroup >= 2
                ? // We keep a single list in case it has been added before.
                  _.uniqBy(
                    this.listStationsAndGroups.concat(elements),
                    'rithmId',
                  )
                : elements;
            this.canLoadMoreStationsAndGroups =
              elements.length >= this.pageSizeStationAndGroup;
            // If the search is empty we can add the current station.
            if (!this.searchStationAndGroup.length) {
              // Find the index of the object.
              const index = this.listStationsAndGroups.findIndex(
                (item) => item.rithmId === this.stationRithmId(),
              );
              if (index !== -1) {
                // We put it in the first position.
                const [item] = this.listStationsAndGroups.splice(index, 1);
                this.listStationsAndGroups.unshift(item);
              } else {
                // If the option has never existed we add it.
                this.listStationsAndGroups.unshift(this.currentStationOption);
              }
            }
            this.searchStationAndGroup = '';
          } else {
            // Create backup for stations and groups.
            this.stationAndGroupsBackupIds = _.map(elements, 'rithmId');
          }

          for (const element of elements) {
            this.stationsAndGroups = {
              ...this.stationsAndGroups,
              [element.rithmId]: {
                rithmId: element.rithmId,
                name: element.name,
                description: element.description,
                type: element.type,
              },
            };
          }

          this.isLoadingStationsAndGroups = false;
          this.isErrorGetStationsAndGroups = false;
          isGetSpecificData && this.enableControlForm('elementSelectControl');
        },
        error: () => {
          isGetSpecificData && this.enableControlForm('elementSelectControl');
          this.isLoadingStationsAndGroups = false;
          this.isErrorGetStationsAndGroups = true;
          this.popupService.notify(
            "Something went wrong on our end and we're looking into it. Please try again in a little while.",
            true,
          );
        },
      });
  }

  /**
   * Disable control.
   * @param control Control to disable.
   */
  disableControlForm(control: string): void {
    this.memberActionForm.controls[control].disable();
  }

  /**
   * Enable control.
   * @param control Control to enable.
   */
  enableControlForm(control: string): void {
    this.memberActionForm.controls[control].enable();
  }

  /**
   * Get members of organization.
   * @param isScroll If load with scroll.
   * @param isReloadMemberList If the request is for reload members.
   */
  getMembersAllOrg(isScroll = false, isReloadMemberList = false): void {
    if (isScroll) {
      this.isLoadingScroll = true;
      this.pageNumMembers += 1;
    } else {
      this.pageNumMembers = 1;
      this.isMembersLoading = true;
    }
    this.canLoadPages = false;
    this.organizationService
      .getManagementMembers(
        EntityType.Organization,
        this.userService.user.organization,
        this.pageSize,
        this.pageNumMembers,
        [],
        [],
        false,
        true,
        this.searchMembers,
      )
      .pipe(first())
      .subscribe({
        next: (members) => {
          if (isScroll) {
            this.members = this.members.concat(members);
          } else {
            this.members = members;
          }
          this.canLoadPages = members.length >= this.pageSize;
          this.isMembersLoading = false;
          this.isLoadingScroll = false;
          this.dataSourceTable.data = [
            ...this.questionsElementStatics.filter((question) =>
              question.firstName
                ?.toLowerCase()
                .includes(this.searchMembers.toLowerCase()),
            ),
            ...this.members,
          ];
          !isReloadMemberList && this.action() && this.setEditActionMembers();
        },
        error: () => {
          this.isMembersLoading = false;
          this.isLoadingScroll = false;
        },
      });
  }

  /**
   * Select all members.
   * @param isChecked Whether the check is checked.
   */
  setAllMembers(isChecked: boolean): void {
    if (isChecked) {
      this.dataSourceTable.data.forEach((element) => {
        if (!this.selectionsTable[element.rithmId]) {
          this.addElement(
            element,
            this.memberActionForm.controls.elementMemberAction.value ===
              this.actionsType.RemoveMember,
            true,
          );
        }
      });
    } else {
      this.selectionsTable = {};
    }
  }

  /**
   * Select members from table.
   * @param member Member selected.
   * @param isChecked Whether the check is checked.
   */
  selectAsMember(
    member: ManagementMembersData | StaticMemberQuestions,
    isChecked: boolean,
  ): void {
    const isRemoveMember =
      this.memberActionForm.controls.elementMemberAction.value ===
      this.actionsType.RemoveMember;

    if (isChecked) {
      this.addElement(member, isRemoveMember, true);
    } else {
      delete this.selectionsTable[member.rithmId];
    }
  }

  /**
   * Select members from table.
   * @param member Member selected.
   * @param isChecked Whether the check is checked.
   */
  selectAsWorker(
    member: ManagementMembersData | StaticMemberQuestions,
    isChecked: boolean,
  ): void {
    const isRemoveMember =
      this.memberActionForm.controls.elementMemberAction.value ===
      this.actionsType.RemoveMember;

    if (isChecked) {
      this.addElement(member, isChecked, !isRemoveMember);
    } else {
      delete this.selectionsTable[member.rithmId];
    }
  }

  /**
   * Add member selected.
   * @param member Member selected.
   * @param isWorker Whether members is worker.
   * @param isMember Whether members is selected.
   */
  addElement(
    member: ManagementMembersData | StaticMemberQuestions,
    isWorker: boolean,
    isMember: boolean,
  ): void {
    this.selectionsTable = {
      ...this.selectionsTable,
      [member.rithmId]: {
        id: member.rithmId,
        isWorker,
        isMember,
        type: member.memberType,
        firstName: member.firstName || member.teamName || '',
        lastName: member.lastName,
        profileImageRithmId: member.profileImageRithmId,
      },
    };
    this.allMemberSelected =
      Object.keys(this.selectionsTable).length ===
      this.dataSourceTable.data.length;
  }

  /**
   * Popup confirmation delete action.
   */
  async confirmDeleteAction(): Promise<void> {
    const response = await this.popupService.confirm({
      title: 'Are you sure?',
      message: 'This Action will be deleted',
      okButtonText: 'Yes',
      cancelButtonText: 'No',
      important: true,
    });
    if (response) {
      this.deleteAction();
    }
  }

  /** Delete action. */
  deleteAction(): void {
    this.deletingAction = true;
    const action = this.action() as PowerAction;
    this.powerService
      .deleteAction(this.ruleRithmId(), action.rithmId)
      .pipe(first())
      .subscribe({
        next: () => {
          this.powerService.setPowerAction({
            eventAction: PowerEventType.Delete,
            action,
            powerRithmId: this.ruleRithmId(),
          });
          this.deletingAction = false;
        },
        error: () => {
          this.deletingAction = false;
          this.popupService.notify('Error to delete action', true);
        },
      });
  }

  /** Edit action. */
  editAction(): void {
    this.memberActionForm.controls['elementMemberAction'].setValue(
      this.action()?.type,
    );
    this.memberActionForm.controls['elementSelectControl'].setValue([
      ...this.actionToEdit.target.stations,
      ...this.actionToEdit.target.groups,
    ]);
  }

  /**
   * Set members previously save.
   */
  setEditActionMembers(): void {
    this.actionToEdit.source.questions.forEach((element) => {
      this.addElement(
        {
          rithmId: element.rithmId,
          profileImageRithmId: element.profileImageRithmId || '',
          firstName: element.firstName,
          // If it is an assigned type otherwise it is a created type, otherwise user selected.
          memberType:
            element.rithmId === 'info:assignedTo'
              ? QuestionFieldType.AssignedUser
              : element.rithmId === `info:${QuestionFieldType.CreatedBy}`
                ? QuestionFieldType.CreatedBy
                : QuestionFieldType.UserSelect,
          // If you have 'info:' we extract a question type otherwise default user.
          icon: element.rithmId.includes('info:')
            ? QuestionFieldIcon[
                element.rithmId.replace('info:', '') as QuestionFieldType
              ]
            : 'fak fa-user-select',
        },
        element.setAsWorker,
        element.setAsMember,
      );
    });

    [
      ...this.actionToEdit.source.users,
      ...this.actionToEdit.source.teams,
    ].forEach((element) => {
      this.addElement(
        {
          rithmId: element.rithmId,
          profileImageRithmId: element.profileImageRithmId || '',
          firstName: element.firstName,
          lastName: element.lastName || '',
          memberType: TypeMember.User,
          isWorker: element.setAsWorker,
          isMember: element.setAsMember,
        },
        element.setAsWorker,
        element.setAsMember,
      );
    });
  }

  /** Save action. */
  saveAction(): void {
    const data = JSON.stringify(this.getMemberActionObject());
    const action: PowerAction = this.action()
      ? {
          ...(this.action() as PowerAction),
          type: this.memberActionForm.controls.elementMemberAction.value,
          data,
        }
      : {
          rithmId: uuidv4(),
          header: '',
          resultMapping: '',
          type: this.memberActionForm.controls.elementMemberAction.value,
          data,
          order: this.orderAction() + 1,
          target: this.stationRithmId(),
        };
    if (this.action()) {
      if (this.action()?.data !== data || this.action()?.type !== action.type) {
        this.orderOfOperations
          ? this.addOrUpdateSpecificAction(action, PowerEventType.Update)
          : this.updateAction(action);
      } else {
        this.closeForm();
      }
    } else {
      this.orderOfOperations
        ? this.addOrUpdateSpecificAction(action, PowerEventType.Add)
        : this.actionManagementMember.emit(action);
    }
  }

  /**
   * Update action on local array.
   * @param action Action to update.
   */
  updateAction(action: PowerAction): void {
    this.action.update(() => action);
    this.hasBeenUpdated.emit();
    this.closeForm();
  }

  /**
   * Add or update action.
   * @param powerAction The current action to be created or updated.
   * @param powerEvent The current action to be created or updated.
   */
  addOrUpdateSpecificAction(
    powerAction: PowerAction,
    powerEvent: PowerEventType,
  ): void {
    this.loadingUpdatingAction = true;
    this.powerService
      .addOrUpdateSpecificAction(this.ruleRithmId(), powerAction)
      .pipe(first())
      .subscribe({
        next: () => {
          this.powerService.setPowerAction({
            eventAction: powerEvent,
            action: powerAction,
            powerRithmId: this.ruleRithmId(),
          });
          this.loadingUpdatingAction = false;
          this.hasBeenUpdated.emit();
          this.closeForm();
        },
        error: () => {
          this.loadingUpdatingAction = false;
          this.popupService.notify('Error saving member action', true);
        },
      });
  }

  /**
   * Get member action object.
   * @returns Object management member action.
   */
  getMemberActionObject(): ManagementMembersActionData {
    const questions: ManagementMembersActionDataItem[] = [];
    const users: ManagementMembersActionDataItem[] = [];
    const teams: ManagementMembersActionDataItem[] = [];

    Object.values(this.selectionsTable).forEach((member) => {
      // If these types are included it is questions, otherwise teams or users.
      const managementMembersAction = [
        QuestionFieldType.AssignedUser,
        QuestionFieldType.UserSelect,
        QuestionFieldType.CreatedBy,
      ].includes(member.type as QuestionFieldType)
        ? questions
        : member.type === TypeMember.Team
          ? teams
          : users;

      const memberAction: ManagementMembersActionDataItem = {
        rithmId: member.id,
        setAsWorker: member.isWorker,
        setAsMember: member.isMember,
        firstName: member.firstName,
        lastName: member.lastName,
      };

      if (member.profileImageRithmId) {
        memberAction.profileImageRithmId = member.profileImageRithmId;
      }
      managementMembersAction.push(memberAction);
    });
    const stationsOrGroups: string[] =
      this.memberActionForm.controls.elementSelectControl.value;
    return {
      source: {
        questions,
        users,
        teams,
      },
      target: {
        stations: stationsOrGroups.filter(
          (station) => this.stationsAndGroups[station]?.type === 'station',
        ),
        groups: stationsOrGroups.filter(
          (group) => this.stationsAndGroups[group]?.type === 'group',
        ),
      },
    };
  }

  /**
   * Sending search value to get mach result.
   */
  getSearchResult(): void {
    setTimeout(() => {
      this.getMembersAllOrg();
    });
  }

  /**
   * Method for processing and updating a search.
   * @param searchValue Search value.
   */
  onSearchChange(searchValue: string): void {
    this.searchUpdated$.next(searchValue);
  }

  /**
   * Validate scroll to get members.
   * @param scroll Event of callback scroll.
   */
  nextPageInfinityScrollDown(scroll: boolean): void {
    if (scroll && !this.isLoadingScroll && this.canLoadPages) {
      this.getMembersAllOrg(true);
    }
  }

  /**
   * TrackBy in table.
   * @param index Number of index.
   * @returns Item id index the element.
   */
  trackBy(index: number): string {
    return index.toString();
  }

  /**
   * Remove the selected element 'station or group'.
   * @param index The selected index.
   */
  removeSelectedElement(index: number): void {
    const newData: [] = [
      ...(this.memberActionForm.controls['elementSelectControl'].value as []),
    ];
    // If there are station or group ids to remove from the backup.
    if (
      this.stationAndGroupsBackupIds.length &&
      this.stationAndGroupsBackupIds.includes(newData[index])
    ) {
      const elementId = newData[index] as string;
      this.stationAndGroupsBackupIds = _.filter(
        this.stationAndGroupsBackupIds,
        (id) => id !== elementId,
      );
    }
    newData.splice(index, 1);
    this.memberActionForm.controls['elementSelectControl'].setValue(newData);
  }

  /** Close form. */
  closeForm(): void {
    this.closeEvent.emit();
  }

  /** Infinity scroll for get more elements. */
  getMoreElements(): void {
    if (!this.isLoadingStationsAndGroups && this.canLoadMoreStationsAndGroups) {
      this.pageNumStationAndGroup++;
      this.getStationsAndGroups();
    }
  }

  /**
   * The station or group selected from the list.
   * @param elementId The id of the clicked element.
   */
  selectStationOrGroupBackup(elementId: string): void {
    // If there are station or group ids to add or remove from the backup.
    if (this.stationAndGroupsBackupIds.length) {
      this.stationAndGroupsBackupIds = _.filter(
        this.stationAndGroupsBackupIds,
        (id) => id !== elementId,
      );
      const currentValues = this.memberActionForm.controls[
        'elementSelectControl'
      ].value as string[];
      const filterOptions = _.uniq([
        ...this.stationAndGroupsBackupIds,
        ...currentValues,
      ]);
      this.memberActionForm.controls['elementSelectControl'].setValue(
        filterOptions,
      );
    }
  }

  /** Method when destroy component. */
  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
